import {
  useFontWeight,
  useTextLeading,
  textSizes,
  textColor,
} from '@/src/packages/theme';
import { VariantProps } from 'tailwind-variants';
import { Box, IBoxProps } from '@/src/packages/components';

type TextVariants = VariantProps<typeof useTextLeading> &
  VariantProps<typeof textColor> &
  VariantProps<typeof textSizes> &
  VariantProps<typeof useFontWeight>;

export type ITextProps = TextVariants & IBoxProps;

export const Text = (props: ITextProps) => {
  const { as = 'p', fontFamily = 'sans', children, ...rest } = props;

  return (
    <Box as={as} fontFamily={fontFamily} {...rest}>
      {children}
    </Box>
  );
};
