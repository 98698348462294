'use client';
import { ProductDetailsShippingReturnsInfo } from '@/src/packages/components/ui/SheetShippingReturnsInfo';
import { Footer } from '@/src/packages/components/ui/Footer';
import { Navigation } from '@/src/packages/components/ui/Navigation';
import { NewsletterPopup } from '@/src/packages/components/ui/NewsletterPopup';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';
import { ProductDetailsWarranty } from '@/src/packages/components/ui/SheetWarranty';
import { Flex } from '@/src/packages/components/ui/Flex';
import { SheetSizeGuides } from '@/src/packages/components/ui/SheetSizeGuides';

const variants = {
  hidden: { opacity: 0 },
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

export const LayoutMain = ({ children }: { children: ReactNode }) => {
  return (
    <Flex className="min-h-screen flex-col">
      <Navigation isNavigationInversed={false} />
      <ProductDetailsShippingReturnsInfo />
      <ProductDetailsWarranty />
      <SheetSizeGuides />
      <AnimatePresence>
        <motion.div
          initial="hidden"
          animate="enter"
          exit="exit"
          variants={variants}
          className="flex flex-1 flex-col"
        >
          {children}
        </motion.div>
      </AnimatePresence>
      <NewsletterPopup />
      <Footer />
    </Flex>
  );
};
