'use client';
import NextLink from 'next/link';
import { getRoute } from '@/src/packages/utils/routes';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SignupToNewsletterDto } from '@bitmap/contracts';
import { NewsletterFormInput } from './NewsletterFormInput';
import { TypeOf } from 'zod';
import { client } from '@/src/packages/server/clients/api';
import { paths } from '@/src/packages/routes';
import { Text } from '@/src/packages/components/ui/Text';
import { Box } from '@/src/packages/components/ui/Box';
import { Link } from '@/src/packages/components/ui/Link';
import { Strong } from '@/src/packages/components/ui/Strong';

export const NewsletterForm = () => {
  const { mutate, isSuccess, isLoading } =
    client.settings.signUpToNewsletter.useMutation({
      onError: (props) => {
        form.setError('email', {
          message:
            props.status === 400
              ? props.body.message
              : 'Something went wrong. Please try again.',
        });
      },
    });

  const form = useForm<TypeOf<typeof SignupToNewsletterDto>>({
    resolver: zodResolver(SignupToNewsletterDto),
    defaultValues: {
      email: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const handleSubmit = (values: TypeOf<typeof SignupToNewsletterDto>) => {
    mutate({ body: values });
  };

  return (
    <FormProvider {...form}>
      {isSuccess ? (
        <Box className="mt-3 space-y-2 bg-black p-4">
          <Text>
            We have sent a confirmation email to{' '}
            <Strong>{form.getValues().email}</Strong>. Please check your inbox.
            You might also need to check your SPAM folder. Any problems, get in
            touch.
          </Text>
        </Box>
      ) : (
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <Box marginBottom={3}>
            <NewsletterFormInput isSubmitting={isLoading} />
          </Box>
          <Box className="text-text-secondary text-sm">
            By providing your email address, you agree to the terms set out in
            our{' '}
            <Link asChild variant="solid" className="text-text-primary">
              <NextLink href={getRoute({ route: paths.TERMS_INDEX })}>
                Terms & conditions
              </NextLink>
            </Link>{' '}
            and{' '}
            <Link asChild variant="solid" className="text-text-primary">
              <NextLink href={getRoute({ route: paths.PRIVACY_INDEX })}>
                Privacy policy
              </NextLink>
            </Link>
            .
          </Box>
        </form>
      )}
    </FormProvider>
  );
};
