import { Box, IBoxProps } from '@/src/packages/components';

type IFooterLinksHeadingProps = IBoxProps;

const FooterColumnHeading = (props: IFooterLinksHeadingProps) => {
  const { children, ...rest } = props;

  return (
    <Box fontWeight="medium" {...rest}>
      {children}
    </Box>
  );
};

export { FooterColumnHeading };
