'use client';
import { Stack, Divider, Box } from '@/src/packages/components';
import * as Accordion from '@radix-ui/react-accordion';
import { ReactNode, Ref, forwardRef, useState } from 'react';
import { CaretRight } from '@carbon/icons-react';
import { FooterNewsletter } from '../FooterNewsletter';
import { FooterLinksConfig } from '../Footer.config';
import { FooterLink } from '../FooterLink';
import { FooterColumnHeading } from '@/src/packages/components/ui/Footer/FooterColumnHeading';
import { cn } from '@/src/packages/utils/cn';
import NextLink from 'next/link';

type IFooterMobileLinksTrigger = {
  children: ReactNode;
  isOpen: boolean;
};

export const FooterMobileLinksTrigger = forwardRef(
  (props: IFooterMobileLinksTrigger, ref: Ref<HTMLDivElement>) => {
    const { children, isOpen, ...rest } = props;

    return (
      <Box
        as="button"
        ref={ref}
        className="flex w-full cursor-pointer items-center text-left"
        paddingY={5}
        {...rest}
      >
        <FooterColumnHeading className="flex-1">{children}</FooterColumnHeading>

        <CaretRight
          className={cn('transition-all', {
            'rotate-90': isOpen,
          })}
        />
      </Box>
    );
  },
);

FooterMobileLinksTrigger.displayName = 'FooterLinksTrigger';

export const FooterMobileLinks = () => {
  const [value, setValue] = useState<string[]>([]);

  return (
    <Box className="px-gutter lg:hidden">
      <FooterNewsletter />
      <Divider className="my-8" />
      <Accordion.Root
        type="multiple"
        value={[...value]}
        onValueChange={setValue}
      >
        <Stack divider={<Divider />} spaceY={0}>
          {FooterLinksConfig.map((link, i) => {
            return (
              <Accordion.Item value={link.title} key={i}>
                <Accordion.Trigger asChild>
                  <FooterMobileLinksTrigger isOpen={value.includes(link.title)}>
                    {link.title}
                  </FooterMobileLinksTrigger>
                </Accordion.Trigger>
                <Accordion.Content className="pb-6">
                  <Stack spaceY={3}>
                    {link.links.map((l, i) => {
                      if (!l.blank) {
                        return (
                          <FooterLink key={i} asChild>
                            <NextLink href={l.href}>{l.title}</NextLink>
                          </FooterLink>
                        );
                      } else {
                        return (
                          <FooterLink key={i} asChild>
                            <Box
                              as="a"
                              href={l.href}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {l.title}
                            </Box>
                          </FooterLink>
                        );
                      }
                    })}
                  </Stack>
                </Accordion.Content>
              </Accordion.Item>
            );
          })}
        </Stack>
      </Accordion.Root>
    </Box>
  );
};
