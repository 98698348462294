import { Cookies } from '@/src/packages/cookies/cookies.enum';
import { parseCookies } from 'nookies';

export const isNewsletterPopupDismissed = (): boolean => {
  const cookie = parseCookies()[Cookies.HIDE_NEWSLETTER_POPUP_COOKIE];

  let isDismissed = false;

  if (cookie) {
    try {
      isDismissed = JSON.parse(cookie);
    } catch (e) {
      isDismissed = false;
    }
  }

  return isDismissed;
};
