'use client';
import { Center, Box, Paragraph, Stack } from '@/src/packages/components';
import { hideNewsletterPopup } from '@/src/packages/utils/hideNewsletterPopup';
import { Close } from '@carbon/icons-react';
import { NewsletterForm } from '../NewsletterForm';
import { useEffect, useState } from 'react';
import { isNewsletterPopupDismissed } from '@/src/packages/utils/isNewsletterPopupDismissed';
import { getStoreWelcomeDiscount } from '@/src/packages/utils/getStoreWelcomeDiscount';
import Image from 'next/image';
import { useDisclosure } from '@bitmap/utils/hooks/use-disclosure';
import {
  ANALYTICS_EVENT_CATEGORIES,
  ANALYTICS_EVENT_NAMES,
  trackEvent,
} from '@/src/packages/server/clients/analytics';
import { useAppSelector } from '@/src/packages/store';

export const NewsletterPopup = () => {
  const { welcomeDiscount } = useAppSelector((state) => state.settingsSlice);

  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: !isNewsletterPopupDismissed(),
  });

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const handleDismiss = () => {
    trackEvent({
      name: ANALYTICS_EVENT_NAMES.NEWSLETTER_POPUP_DISMISS,
      category: ANALYTICS_EVENT_CATEGORIES.NEWSLETTER_POPUP,
    });

    onClose();
    hideNewsletterPopup();
  };

  if (welcomeDiscount) {
    if (isOpen && isMounted) {
      return (
        <Box
          position="fixed"
          zIndex="navigation"
          className="bg-background-primary bottom-0 left-0 w-[calc(100%-var(--space-8))] max-w-sm border-r border-t border-black lg:block"
        >
          <Box
            className="absolute right-0 top-0 z-10 h-10 w-10 cursor-pointer border-b border-l border-black bg-white"
            onClick={handleDismiss}
          >
            <Center>
              <Close size={20} />
            </Center>
          </Box>
          <Box className="relative aspect-[4/3]">
            <Image
              className="object-cover"
              src="//images.ctfassets.net/l194802zn0cr/40NwQ373WeMnVKpvtXrJmf/d3a0129172254bea55b6e2f52f6fe37c/IMG_3300.jpg"
              alt="Sign up to our newsletter"
              fill
              sizes="400px"
              priority
            />
          </Box>
          <Stack padding={8} spaceY={4}>
            <Paragraph size="large">
              Sign up to our newsletter for the occasional update, and{' '}
              {getStoreWelcomeDiscount(welcomeDiscount)} off your first order.
            </Paragraph>
            <NewsletterForm />
          </Stack>
        </Box>
      );
    }

    return (
      <Box
        className="bg-background-secondary fixed bottom-0 left-0 z-50 hidden px-5 py-3 lg:block"
        onClick={onOpen}
      >
        {getStoreWelcomeDiscount(welcomeDiscount)} off your first order
      </Box>
    );
  }

  return <></>;
};
