import { getStoreWelcomeDiscount } from '@/src/packages/utils/getStoreWelcomeDiscount';
import { NewsletterForm } from '../../NewsletterForm';
import { useAppSelector } from '@/src/packages/store';
import { Box } from '@/src/packages/components/ui/Box';
import { Center } from '@/src/packages/components/ui/Center';
import { Flex } from '@/src/packages/components/ui/Flex';
import { CarbonIconType } from '@carbon/icons-react';
import { createElement } from 'react';
import { Paragraph } from '@/src/packages/components/ui/Paragraph';
import { Stack } from '@/src/packages/components/ui/Stack';
import { Link } from '@/src/packages/components/ui/Link';
import { FooterColumnHeading } from '@/src/packages/components/ui/Footer/FooterColumnHeading';
import { StoreConfig } from '@/src/store.config';

export const FooterNewsletterIntro = () => {
  const { welcomeDiscount } = useAppSelector((state) => state.settingsSlice);

  return (
    <Stack spaceY={2}>
      <FooterColumnHeading>Keep in touch</FooterColumnHeading>
      <Paragraph>
        {welcomeDiscount ? (
          <>
            Be the first to hear about our new product launches, and get{' '}
            {getStoreWelcomeDiscount(welcomeDiscount)} off your first order.
          </>
        ) : (
          <>Sign up to be the first to hear about our new product launches.</>
        )}
      </Paragraph>
    </Stack>
  );
};

const FooterContactButton = ({
  label,
  icon,
  handleClick,
}: {
  label: string;
  icon: CarbonIconType;
  handleClick(): void;
}) => {
  return (
    <Flex
      className="cursor-pointer items-center space-x-2 hover:underline"
      onClick={handleClick}
    >
      <Box className="h-9 w-9 rounded-full bg-black">
        <Center>{createElement(icon, { size: 16 })}</Center>
      </Box>
      <Box className="leading-none">{label}</Box>
    </Flex>
  );
};

export const FooterNewsletter = () => {
  return (
    <Stack spaceY={4}>
      <FooterNewsletterIntro />
      <Stack spaceY={6}>
        <NewsletterForm />
        <Stack spaceX={4} className="flex" direction="horizontal">
          <Link variant="solid" asChild>
            <Box
              as="a"
              href={StoreConfig.instagram_url}
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </Box>
          </Link>
          <Link variant="solid" asChild>
            <Box
              as="a"
              href={StoreConfig.pinterest_url}
              target="_blank"
              rel="noreferrer"
            >
              Pinterest
            </Box>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};
