import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../Form/HookForm';
import { TypeOf } from 'zod';
import { SignupToNewsletterDto } from '@bitmap/contracts';
import { Box } from '../Box';
import { Center } from '../Center';
import { Loader } from '../Loader';
import { useBorderColor } from '@/src/packages/theme/useBorderColor';
import { ArrowRight } from 'lucide-react';
import { tv } from 'tailwind-variants';
import { Input } from '@/src/packages/components/ui/Input';
import { useEffect, useState } from 'react';

const inputStyles = tv({
  base: clsx('relative flex h-12 transition-all'),
  variants: {
    error: { true: useBorderColor({ borderColor: 'error' }) },
  },
});

export const NewsletterFormInput = ({
  isSubmitting,
}: {
  isSubmitting: boolean;
}) => {
  const { control } = useFormContext<TypeOf<typeof SignupToNewsletterDto>>();

  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!isMounted) return <></>;

  return (
    <FormField
      control={control}
      name="email"
      render={({ field, fieldState: { isDirty, error } }) => (
        <FormItem>
          <FormControl>
            <>
              <Box className={inputStyles({ error: error ? true : false })}>
                <Input
                  type="text"
                  disabled={isSubmitting}
                  placeholder="Enter your email address"
                  autoComplete="off"
                  {...field}
                />
                {isDirty && (
                  <Box
                    as="button"
                    type="submit"
                    className="w-12 flex-shrink-0 h-full cursor-pointer text-white bg-black"
                  >
                    <Center>
                      {isSubmitting ? <Loader /> : <ArrowRight size={16} />}
                    </Center>
                  </Box>
                )}
              </Box>

              {isDirty && <FormMessage />}
            </>
          </FormControl>
        </FormItem>
      )}
    />
  );
};
