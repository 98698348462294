import { Box } from '@/src/packages/components/ui/Box';
import { FooterCopyright } from './FooterCopyright';
import { FooterDesktopLinks } from './FooterDesktopLinks';
import { FooterMobileLinks } from './FooterMobileLinks';

export const Footer = () => {
  return (
    <Box as="footer" className="border-t py-12 lg:pt-0">
      <FooterMobileLinks />
      <FooterDesktopLinks />
      <FooterCopyright />
    </Box>
  );
};
