import { Discount } from "@bitmap/database";

type Options = {
  amount: Discount["amount"];
  type: Discount["type"];
};

export const getStoreWelcomeDiscount = (options: Options | undefined) => {
  if (options) return `${options.amount}%`;

  return;
};
