import { FooterLink } from '../FooterLink';
import { FooterLinksConfig } from '../Footer.config';
import { Stack } from '@/src/packages/components/ui/Stack';
import { Box } from '@/src/packages/components/ui/Box';
import { InternalLink } from '@/src/packages/components/ui/InternalLink';
import { FooterNewsletter } from '../FooterNewsletter';
import { Container } from '@/src/packages/components/ui/Container';
import { FooterColumnHeading } from '@/src/packages/components/ui/Footer/FooterColumnHeading';
import { FooterColumn } from '@/src/packages/components/ui/Footer/FooterColumn';

export const FooterDesktopLinks = () => {
  return (
    <Container>
      <Box className="hidden grid-cols-12 lg:grid">
        <FooterColumn className="col-span-4 border-r pr-12">
          <FooterNewsletter />
        </FooterColumn>

        <Box className="col-span-8 grid w-full grid-cols-12 pl-12">
          {FooterLinksConfig.map((link, i) => {
            return (
              <FooterColumn
                className="col-span-6 space-y-2.5 xl:col-span-3"
                key={i}
              >
                <FooterColumnHeading>{link.title}</FooterColumnHeading>
                <Stack spaceY={2}>
                  {link.links.map((l, i) => {
                    if (!l.blank) {
                      return (
                        <InternalLink href={l.href} key={i}>
                          <FooterLink>{l.title}</FooterLink>
                        </InternalLink>
                      );
                    } else {
                      return (
                        <Box
                          as="a"
                          href={l.href}
                          key={i}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FooterLink>{l.title}</FooterLink>
                        </Box>
                      );
                    }
                  })}
                </Stack>
              </FooterColumn>
            );
          })}
        </Box>
      </Box>
    </Container>
  );
};
