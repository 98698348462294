import { useStoreContext } from '@/src/packages/components/contexts/store-context-provider.context';
import { ShippingReturnsInfo } from '@/src/packages/components/pages/ShippingReturns';
import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
} from '@/src/packages/components/ui/Sheet';
import { useShippingReturnsDrawerStore } from '@/src/packages/hooks/use-basket-drawer';

export const ProductDetailsShippingReturnsInfo = () => {
  const { shippingRates } = useStoreContext();

  const { isOpen, handleClose } = useShippingReturnsDrawerStore();

  return (
    <Sheet open={isOpen} onOpenChange={handleClose}>
      <SheetContent side="right">
        <SheetHeader title="Delivery & returns" />
        <SheetBody>
          <ShippingReturnsInfo shippingRates={shippingRates} />
        </SheetBody>
      </SheetContent>
    </Sheet>
  );
};
