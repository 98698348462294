import { SizeGuides } from '@/src/packages/components/pages/SizeGuide';
import { Link } from '@/src/packages/components/ui/Link';
import { Paragraph } from '@/src/packages/components/ui/Paragraph';
import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetFooter,
  SheetHeader,
} from '@/src/packages/components/ui/Sheet';
import { useSizeGuideDrawerStore } from '@/src/packages/hooks/use-basket-drawer';
import { paths } from '@/src/packages/routes';
import { getRoute } from '@/src/packages/utils/routes';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

export const SheetSizeGuides = () => {
  const { isOpen, handleClose } = useSizeGuideDrawerStore();

  const path = usePathname();

  useEffect(() => {
    handleClose();
  }, [path]);

  return (
    <Sheet open={isOpen} onOpenChange={handleClose}>
      <SheetContent side="right">
        <SheetHeader title="Size guides" />
        <SheetBody>
          <SizeGuides />
        </SheetBody>
        <SheetFooter className="bg-background-secondary flex h-20 items-center justify-center">
          <Paragraph>
            Unsure what size to get?{' '}
            <Link asChild variant="solid">
              <NextLink href={getRoute({ route: paths.CONTACT_INDEX })}>
                Get in touch
              </NextLink>
            </Link>
            .
          </Paragraph>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};
