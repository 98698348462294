import { Box, Container, Link } from '@/src/packages/components';
import { ReactNode } from 'react';
import { NavigationLocaleSelectorTrigger } from '@/src/packages/components/ui/Navigation/NavigationLocaleSelector';
import { useLocaleDrawer } from '@/src/packages/hooks/use-basket-drawer';

export const FooterCopyrightLink = ({ children }: { children: ReactNode }) => {
  return <Link>{children}</Link>;
};

const FooterLocaleSelectorToggle = () => {
  const { handleOpen } = useLocaleDrawer();

  return (
    <Link onClick={handleOpen}>
      <NavigationLocaleSelectorTrigger />
    </Link>
  );
};

export const FooterCopyright = () => {
  return (
    <Box className="flex-col border-t pt-8">
      <Container className="flex">
        <Box>© Albert & Arthur</Box>

        <Box className="ml-auto">
          <FooterLocaleSelectorToggle />
        </Box>
      </Container>
    </Box>
  );
};
