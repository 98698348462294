export type IColumn<T> = {
  accessor: keyof T;
  header: string;
  Cell?(row: T): JSX.Element;
};

export const Table = <T,>({
  data,
  columns,
}: {
  data: T[];
  columns: IColumn<T>[];
}) => {
  return (
    <table className="w-full table-fixed">
      <thead>
        <tr>
          {columns.map((column, i) => {
            return (
              <th className="pb-3 text-left font-medium leading-none" key={i}>
                {column.header}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => {
          return (
            <tr key={i}>
              {columns.map((column, i) => {
                return (
                  <td
                    className="border-t border-gray-200 py-3 leading-none"
                    key={i}
                  >
                    {column.Cell
                      ? column.Cell(row)
                      : // @ts-ignore
                        row[column.accessor as any]}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
