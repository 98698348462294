'use client';
import { MeasurementUnits } from '@/src/enum/measurement-units.enum';
import { useState } from 'react';
import { SizeUnitToggle } from './SizeUnitToggle';
import { CommerceUtils } from '@bitmap/contracts';
import { IColumn, Table } from '@/src/packages/components/ui/Table';
import { Paragraph } from '@/src/packages/components/ui/Paragraph';
import { Stack } from '@/src/packages/components/ui/Stack';
import { Box } from '@/src/packages/components/ui/Box';
import { Divider } from '@/src/packages/components/ui/Divider';
import { Section } from '@/src/packages/components/ui/Section';
import { Link } from '@/src/packages/components/ui/Link';
import { Heading } from '@/src/packages/components/ui/Heading';
import NextLink from 'next/link';
import { TemplateSidebar } from '@/src/packages/components/templates/TemplateSidebar';
import { CustomerSupportLinks } from '@/src/packages/components/ui/Footer/Footer.config';

import { pathNames } from '@/src/packages/routes/pathNames.enum';
import { getRoute } from '@/src/packages/utils/routes';
import { paths } from '@/src/packages/routes';

const SizeGuideHeader = ({
  title,
  unit,
  setUnit,
}: {
  title: string;
  unit: MeasurementUnits;
  setUnit(unit: MeasurementUnits): void;
}) => {
  return (
    <Box className="mb-4 flex items-center">
      <Heading as="h2" className="font-serif text-2xl font-medium">
        {title}
      </Heading>

      <Box className="ml-auto">
        <SizeUnitToggle value={unit} onChange={setUnit} />
      </Box>
    </Box>
  );
};

type size = {
  name: string;
  from: number;
  to: number;
  strapWidth: number;
};

type LeashSize = {
  name: string;
  length: number;
  strapWidth: number;
};

export const SizeGuideCollar = () => {
  const [unit, setUnit] = useState<MeasurementUnits>(MeasurementUnits.cm);

  const collarSizes: size[] = [
    {
      name: 'S',
      from: 30,
      to: 38,
      strapWidth: 2,
    },
    {
      name: 'M',
      from: 38,
      to: 46,
      strapWidth: 2,
    },
    {
      name: 'L',
      from: 46,
      to: 54,
      strapWidth: 2,
    },
  ];

  const columns: IColumn<size>[] = [
    {
      accessor: 'name',
      header: 'Size',
      Cell: (row) => {
        return <div>{row.name}</div>;
      },
    },
    {
      accessor: 'from',
      header: `Neck (${unit})`,
      Cell: (row) => {
        return (
          <>
            {unit === 'in'
              ? `${CommerceUtils.convertCmToInches(
                  row.from,
                )}" - ${CommerceUtils.convertCmToInches(row.to)}"`
              : `${row.from} - ${row.to} cm`}
          </>
        );
      },
    },
    {
      accessor: 'strapWidth',
      header: `Strap width (${unit})`,
      Cell: (row) => {
        return (
          <>
            {unit === 'in'
              ? `${CommerceUtils.convertCmToInches(row.strapWidth)}"`
              : `${row.strapWidth} cm`}
          </>
        );
      },
    },
  ];

  return (
    <>
      <SizeGuideHeader title="Collar" setUnit={setUnit} unit={unit} />
      <Paragraph marginBottom={5}>
        Where possible, we recommend measuring your dog&apos;s neck. To do this,
        simply take a measuring tape, and measure around your dog&apos;s neck
        where the collar would naturally sit, inserting a couple of your fingers
        underneath the tape for comfort.
      </Paragraph>
      <Table data={collarSizes} columns={columns} />
    </>
  );
};

export const SizeGuideLeash = () => {
  const [unit, setUnit] = useState<MeasurementUnits>(MeasurementUnits.cm);

  const collarSizes: LeashSize[] = [
    {
      name: 'One size',
      length: 120,
      strapWidth: 2,
    },
  ];

  const columns: IColumn<LeashSize>[] = [
    {
      accessor: 'name',
      header: 'Size',
      Cell: (row) => {
        return <div>{row.name}</div>;
      },
    },
    {
      accessor: 'length',
      header: `Length (${unit})`,
      Cell: (row) => {
        return (
          <>
            {unit === 'in'
              ? `${CommerceUtils.convertCmToInches(row.length)}"`
              : `${row.length} cm`}
          </>
        );
      },
    },
  ];

  return (
    <>
      <SizeGuideHeader title="Leash" setUnit={setUnit} unit={unit} />
      <Table data={collarSizes} columns={columns} />
    </>
  );
};

export const SizeGuideHarness = () => {
  const [unit, setUnit] = useState<MeasurementUnits>(MeasurementUnits.cm);

  const harnessSizes: size[] = [
    {
      name: 'XS (Petite)',
      from: 35,
      to: 45,
      strapWidth: 1.2,
    },
    {
      name: 'S (Petite)',
      from: 45,
      to: 56,
      strapWidth: 1.2,
    },
    {
      name: 'S (Regular)',
      from: 42,
      to: 56,
      strapWidth: 2,
    },
    {
      name: 'M (Regular)',
      from: 52,
      to: 67,
      strapWidth: 2,
    },
    {
      name: 'L (Regular)',
      from: 60,
      to: 75,
      strapWidth: 2,
    },
  ];

  const columns: IColumn<size>[] = [
    {
      accessor: 'name',
      header: 'Size',
      Cell: (row) => {
        return <div>{row.name}</div>;
      },
    },
    {
      accessor: 'from',
      header: `Chest (${unit})`,
      Cell: (row) => {
        return (
          <>
            {unit === 'in'
              ? `${CommerceUtils.convertCmToInches(
                  row.from,
                )}" - ${CommerceUtils.convertCmToInches(row.to)}"`
              : `${row.from} - ${row.to} cm`}
          </>
        );
      },
    },
    {
      accessor: 'strapWidth',
      header: `Strap width (${unit})`,
      Cell: (row) => {
        return (
          <>
            {unit === 'in'
              ? `${CommerceUtils.convertCmToInches(row.strapWidth)}"`
              : `${row.strapWidth} cm`}
          </>
        );
      },
    },
  ];

  return (
    <>
      <SizeGuideHeader title="Harness" setUnit={setUnit} unit={unit} />
      <Table data={harnessSizes} columns={columns} />
    </>
  );
};

export const SizeGuideContact = () => {
  return (
    <Paragraph>
      Unsure about what size to get?{' '}
      <Link asChild variant="solid">
        <NextLink href={getRoute({ route: paths.CONTACT_INDEX })}>
          Get in touch
        </NextLink>
      </Link>
      .
    </Paragraph>
  );
};

export const SizeGuides = () => {
  return (
    <>
      <Stack spaceY={8} divider={<Divider />}>
        <Section>
          <SizeGuideCollar />
        </Section>
        <Section>
          <SizeGuideHarness />
        </Section>
        <Section>
          <SizeGuideLeash />
        </Section>
      </Stack>
    </>
  );
};

export const SizeGuide = () => {
  return (
    <TemplateSidebar title="Size guides" links={CustomerSupportLinks}>
      <Box className="max-w-2xl">
        <SizeGuides />
      </Box>
    </TemplateSidebar>
  );
};
