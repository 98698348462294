import { RichText } from '@/src/packages/components/ui/RichText';
import { RichTextConfigArticle } from '@/src/packages/components/ui/RichText/RichText.config';
import {
  Sheet,
  SheetBody,
  SheetContent,
  SheetHeader,
} from '@/src/packages/components/ui/Sheet';
import {
  useGlobalContent,
  useWarrantyDrawerStore,
} from '@/src/packages/hooks/use-basket-drawer';

export const ProductDetailsWarranty = () => {
  const { isOpen, handleClose } = useWarrantyDrawerStore();

  const { warranty } = useGlobalContent();

  return (
    <Sheet open={isOpen} onOpenChange={handleClose}>
      <SheetContent side="right">
        <SheetHeader title="Lifetime warranty" />
        <SheetBody>
          <RichText data={warranty} options={RichTextConfigArticle} />
        </SheetBody>
      </SheetContent>
    </Sheet>
  );
};
