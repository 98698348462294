import { MeasurementUnits } from '@/src/enum/measurement-units.enum';
import { Box } from '@/src/packages/components/ui/Box';
import { cn } from '@/src/packages/utils/cn';

export const SizeUnitToggleOption = ({
  onClick,
  children,
  isActive,
}: {
  onClick(): void;
  children: React.ReactNode;
  isActive: boolean;
}) => {
  return (
    <Box
      onClick={onClick}
      className={cn(
        'flex-1 cursor-pointer rounded-full px-5 py-2 text-center text-xs font-medium leading-none',
        {
          'bg-white shadow-sm': isActive,
          'border-transparent text-gray-500': !isActive,
        },
      )}
    >
      {children}
    </Box>
  );
};

export const SizeUnitToggle = ({
  value,
  onChange,
}: {
  value: MeasurementUnits;
  onChange(unit: MeasurementUnits): void;
}) => {
  return (
    <Box className="bg-background-secondary flex w-48 items-center rounded-full p-0.5">
      <SizeUnitToggleOption
        isActive={value === 'cm'}
        onClick={() => onChange(MeasurementUnits.cm)}
      >
        CM
      </SizeUnitToggleOption>
      <SizeUnitToggleOption
        isActive={value === 'in'}
        onClick={() => onChange(MeasurementUnits.in)}
      >
        IN
      </SizeUnitToggleOption>
    </Box>
  );
};
